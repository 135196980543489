<form nz-form [formGroup]="form" style="text-align: center" class="text-center">
  <p><span nz-icon style="font-size: 32px" [nzType]="'heart'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#71f051'"></span></p>
  <h3>{{ 'ADMIN.USERS.REACTIVATE.MODAL.title' | translate }}</h3>

  <p style="text-align: center">{{ 'ADMIN.USERS.REACTIVATE.MODAL.instruction' | translate }}</p>
  <nz-form-item>
    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="{{ 'ADMIN.USERS.REACTIVATE.MODAL.validation_error' | translate }}">
      <input
        nz-input
        style="text-align: center"
        type="email"
        id="email-confirm"
        formControlName="emailConfirmed"
        [placeholder]="user.email"
        class="mx-auto" />
    </nz-form-control>
  </nz-form-item>
</form>
