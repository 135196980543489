import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import { User } from '../../../../graphql/frontend-data-graphql';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styles: [],
  standalone: true,
  imports: [FormsModule, NzFormModule, ReactiveFormsModule, NzGridModule, NzInputModule]
})
export class PasswordComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(NZ_MODAL_DATA) public user: User
  ) {
    this.form = this.fb.group({
      id: [],
      password: [null, [Validators.required, Validators.minLength(8)]],
      passwordConfirmed: [null, [Validators.required, this.match('password')]]
    });
  }

  // see https://dev.to/jdgamble555/angular-confirm-password-validation-custom-validator-3pkl
  match(matchTo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: true };
    };
  }

  ngOnInit(): void {
    console.log('Password component initialized');
  }
}
