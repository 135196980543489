import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  AsyncValidatorFn,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { LetModule } from '@delon/abc/let';
import { ACLService } from '@delon/acl';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Observable, debounceTime, map, switchMap } from 'rxjs';
import { Organization, OrganizationMembership, OrganizationRole, PlatformRole, User } from 'src/app/graphql/frontend-data-graphql';
import { AuthService } from 'src/app/shared/services/auth.service';
import { OrganizationService } from 'src/app/shared/services/organization.service';
import { UserService } from 'src/app/shared/services/user.service';

interface OrganizationMembershipForm extends OrganizationMembership {
  disabled?: boolean;
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.less'],
  standalone: true,
  imports: [
    FormsModule,
    NzFormModule,
    ReactiveFormsModule,
    NzGridModule,
    NzInputModule,
    NgIf,
    NzCheckboxModule,
    NzToolTipModule,
    NzButtonModule,
    NzIconModule,
    NzDividerModule,
    NzSelectModule,
    NgFor,
    NzWaveModule,
    NzPopoverModule,
    NzTableModule,
    NzTagModule,
    LetModule,
    AsyncPipe,
    TranslateModule
  ]
})
export class UserEditComponent implements OnInit {
  /**
   * Needed for OrgAdmin, to show default settings, when creaing new user
   */
  activeUserOrganizationName = '';
  PlatformRole = PlatformRole;
  OrganizationRole = OrganizationRole;
  userForm: UntypedFormGroup;
  platformRoles: string[];
  organizationRoles: string[];
  isPasswordVisible = false;

  // Organization selection
  showPopoverOrganization = false;
  selectedOrganizationsToAdd: Organization[] = [];
  loadingNewOrg = false;
  checked = false;
  loading = false;
  indeterminate = false;
  organizations$: Observable<Organization[]>;
  listOfCurrentOrga: readonly OrganizationMembershipForm[] = [];
  setOfCheckedId = new Set<string>();
  selectedMultipleRole = null;
  loadingDeleteOrgs = false;
  loadingDeleteOrg = false;

  get userResult() {
    return this.userForm.getRawValue().user as User;
  }

  get organizationMembershipResult() {
    return this.userForm.getRawValue().organization_memberships as OrganizationMembershipForm[];
  }

  isNewUser: boolean = false;
  isAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  isEditUserAdmin: boolean = false;

  asyncUniqueEmailValidator: AsyncValidatorFn = (control: AbstractControl): Observable<ValidationErrors | null> => {
    return this.userSrv.users.fetch({ filter: { email: { eq: control.value } } }).pipe(
      debounceTime(300),
      map(res => {
        return res.data.users.edges.length > 0 ? { unique: true } : null;
      })
    );
  };

  constructor(
    private fb: UntypedFormBuilder,
    private acl: ACLService,
    private organizationsService: OrganizationService,
    private authService: AuthService,
    private userSrv: UserService,
    @Inject(NZ_MODAL_DATA) public user: User
  ) {
    this.platformRoles = Object.keys(PlatformRole);
    this.organizationRoles = Object.keys(OrganizationRole);
    this.organizations$ = this.organizationsService.getOrganizationObservable();

    // this.acl.can([PlatformRole.SuperAdmin, PlatformRole.Admin])
    //   ? this.organizationsService.organizations$
    //   : of([this.authService.organization]);

    // if (!acl.can(PlatformRole.SuperAdmin)) {
    //   this.platformRole = this.platformRole.filter(r => r != PlatformRole.SuperAdmin);
    // }
    // if (!acl.can([PlatformRole.Admin, PlatformRole.SuperAdmin])) {
    //   this.platformRole = this.platformRole.filter(r => r != PlatformRole.Admin);
    // }
  }

  ngOnInit(): void {
    if (!this.user) {
      this.user = {
        email: '',
        login_disabled: false,
        name: '',
        organization_memberships: [],
        // password_hash: '',
        platform_roles: [],
        refresh_tokens: []
      };
      this.isNewUser = true;
    }
    const isOwnUser = this.user.email == this.authService.currentUser.email;
    this.isAdmin = this.acl.can([PlatformRole.Developer, PlatformRole.PlatformAdmin]);
    this.isOrgAdmin = this.acl.can(OrganizationRole.OrganizationAdmin);
    this.isEditUserAdmin = this.user.platform_roles.includes(PlatformRole.Developer);
    if (this.isOrgAdmin) this.activeUserOrganizationName = this.authService.currentUser.active_organization?.organization_name ?? '';

    this.userForm = this.fb.group({
      user: this.fb.group({
        email: [{ value: null, disabled: !this.isNewUser }, [Validators.required, Validators.email], [this.asyncUniqueEmailValidator]],
        password: [null, this.isNewUser ? [Validators.required, Validators.minLength(8)] : []],
        first_name: [null, [Validators.required]],
        last_name: [null, [Validators.required]],
        platform_roles: [{ value: null, disabled: !this.isAdmin }, []],
        login_disabled: [{ value: false, disabled: isOwnUser }],
        created: [null]
      }),
      organization_memberships: [[], []]
    });

    // this.userForm.valueChanges.subscribe(() => console.log(this.userForm));

    this.userForm.controls['user'].patchValue(this.user);
    this.userForm.controls['organization_memberships'].setValue(this.user.organization_memberships);
  }

  findOrgMembership(identifier: string): OrganizationMembershipForm | undefined {
    let userResult = this.user?.organization_memberships?.find(m => m.organization_id == identifier);
    if (userResult) return userResult;
    else return this.organizationMembershipResult.find(m => m.organization_id == identifier);
  }

  changeMembership(identifier: string, roles?: OrganizationRole[], activated = true) {
    if (activated) {
      let result = this.organizationMembershipResult;
      const membership = result.find(m => m.organization_id == identifier);
      if (membership) {
        membership.roles = roles ?? [];
      } else {
        this.organizations$.subscribe(orgs => {
          this.organizationMembershipResult.push(<any>{
            organization_id: identifier,
            organization: orgs.find(o => o.identifier == identifier),
            user_email: this.user.email,
            roles: roles ?? []
          });
        });
      }
    } else {
      const index = this.organizationMembershipResult.findIndex(m => m.organization_id == identifier);
      if (index > -1) {
        this.organizationMembershipResult[index] = { ...this.organizationMembershipResult[index], disabled: true };
      }
    }

    this.userForm.controls['organization_memberships'].setValue([...this.organizationMembershipResult]);
    this.userForm.controls['organization_memberships'].markAsDirty();
  }

  addOrganizations() {
    this.loadingNewOrg = true;
    this.selectedOrganizationsToAdd.forEach(org => {
      this.changeMembership(org.identifier);
    });
    this.selectedOrganizationsToAdd = [];
    setTimeout(() => {
      this.loadingNewOrg = false;
      this.showPopoverOrganization = false;
    }, 200);
  }

  removeMultipleOrganizations() {
    this.loadingDeleteOrg = true;
    this.setOfCheckedId.forEach(id => {
      this.changeMembership(id, undefined, false);
    });
    this.selectedOrganizationsToAdd = [];
    setTimeout(() => {
      this.setOfCheckedId.clear();
      this.refreshCheckedStatus();
      this.loadingDeleteOrg = false;
    }, 200);
  }

  addRoleToMultipleOrgs(role: OrganizationRole) {
    this.loading = true;
    this.setOfCheckedId.forEach(id => {
      const allRoles = new Set<OrganizationRole>().add(role);
      this.findOrgMembership(id)?.roles.forEach(role => allRoles.add(role));
      this.changeMembership(id, Array.from(allRoles));
    });
    setTimeout(() => {
      this.refreshCheckedStatus();
      this.loading = false;
    }, 200);
  }

  onAllChecked(checked: boolean) {
    this.listOfCurrentOrga.forEach(({ organization_id }) => this.updateCheckedSet(organization_id, checked));
    this.refreshCheckedStatus();
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentOrga;
    this.checked = listOfEnabledData.every(({ organization_id }) => this.setOfCheckedId.has(organization_id));
    this.indeterminate = listOfEnabledData.some(({ organization_id }) => this.setOfCheckedId.has(organization_id)) && !this.checked;
  }

  onCurrentPageDataChange(listOfCurrentPageData: readonly OrganizationMembershipForm[]): void {
    this.listOfCurrentOrga = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.user) {
  //     if (!this.user.groups) {
  //       // for deprecated db schema
  //       this.user.groups = {};
  //     }
  //     this.userRoleback = JSON.parse(JSON.stringify(changes.user.currentValue));
  //   }
  // }

  // show() {
  //   this.userModal.show();
  // }

  // hide() {
  //   this.userModal.hide();
  // }

  // unselectUser() {
  //   for (const k in this.userRoleback) {
  //     if (this.userRoleback.hasOwnProperty(k)) {
  //       this.user[k] = this.userRoleback[k];
  //     }
  //   }
  //   this.userRoleback = null;
  //   this.userModal.hide();
  //   this.closed.emit();
  // }

  // resetPassword() {
  //   this.api.resetUserPassword(this.user.id, this.user.password).subscribe(data => {
  //     this.toastr.info('Password has been changed succesfully.', 'Info');
  //   }, error => {
  //     if (error.status.toString().startsWith('4')) {
  //       this.toastr.error(error.error.message, 'Error');
  //     }
  //   });
  // }

  // toggleOrganization(id: string, $event: MouseEvent) {
  //   const val = ($event.currentTarget as HTMLInputElement).checked;
  //   if (val === true && this.user.organizationIds.indexOf(id) === -1) {
  //     this.user.organizationIds.push(id);
  //   }
  //   if (val === false) {
  //     this.user.organizationIds = this.user.organizationIds.filter(item => item !== id);
  //     delete this.user.groups[id];
  //   }
  // }

  // setRole($event: Event, role: string) {
  //   const val = ($event.currentTarget as HTMLInputElement).checked;
  //   if (val === true) {
  //     this.user.platform_role.push(role);
  //   }
  //   if (val === false) {
  //     this.user.platform_role = this.user.platform_role.filter(item => item !== role);
  //   }
  // }

  // get userOrganizations() {
  //   return this.organizations.filter(i => this.user.organizationIds.includes(i.id));
  // }

  // toggleGroup(id: string, group: string, $event: MouseEvent) {
  //   if (this.user.groups[id]?.includes(group)) {
  //     const idx = this.user.groups[id].findIndex(g => g === group);
  //     this.user.groups[id].splice(idx, 1);
  //   } else {
  //     this.user.groups[id] = [...(this.user.groups[id] || []), group];
  //   }
  // }
}
